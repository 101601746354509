import React from 'react';
import { jsPDF } from 'jspdf';
import QRCode from 'qrcode';

const PosReceipt = ({
  storeName = 'Zeliver',
  storeAddress = 'Periyar colony, Tirupur',
  customerName = 'John Doe',
  customerPhone = 'Phone: (123) 456-7890',
  customerAddress = 'Placeholder City, PL 12345',
  orderId = '123456',
  totalAmount = '₹99.99',
  paymentType = 'PREPAID',
  thankYouMessage = 'Thank you for your purchase!',
  footerMessage = 'Visit us again!',
  storeNumber = "8110995777",
  upiId = 'zeliver@ybl'
}) => {
  const handlePrint = async () => {
    // Format totalAmount for UPI payment
    const amount = totalAmount.replace(/[^\d.]/g, ''); // Remove currency symbol and any non-numeric characters
    const upiAmount = parseFloat(amount) > 0 ? amount : ''; // Only include amount if it's greater than 0

    // Generate UPI QR code
    const qrCodeUrl = await QRCode.toDataURL(`upi://pay?pa=${upiId}&pn=Zeliver&mc=0000&tid=000000000000&tr=${orderId}&tn=Payment for Order ${orderId}&am=${upiAmount}&cu=INR&url=`);

    // Create PDF with dynamic height
    const doc = new jsPDF({
      unit: 'mm',
      format: [80, 150], // Initialize with a default height
    });

    // Margins and additional spacing
    const margin = 5;
    const additionalSpace = 10;
    const separatorHeight = 3;

    // Measure content height
    let currentY = 0;

    currentY += 30; // Space for store name and address
    currentY += separatorHeight; // Height of separator line
    currentY += 30; // Space for customer details
    currentY += separatorHeight;
    currentY += 30; // Space for order details
    currentY += separatorHeight;
    currentY += 30; // Space for thank you message and footer
    currentY += 40; // Space for QR code and margin

    // Adjust the height if necessary
    const pageHeight = Math.max(currentY + margin, 150); // Ensure a minimum height of 150mm if needed
    doc.internal.pageSize.height = pageHeight;

    // Store Name
    doc.setFontSize(10);
    doc.setFont('Courier', 'bold');
    doc.text(storeName, 80 / 2, margin + additionalSpace, { align: 'center' });

    // Store Address
    doc.setFontSize(8);
    doc.setFont('Courier', 'normal');
    doc.text(storeAddress, 80 / 2, margin + additionalSpace + 5, { align: 'center' });

    // Separator
    drawSeparator(doc, margin + additionalSpace + 15);

    // Customer Details
    doc.setFontSize(10);
    doc.text(`Customer: ${customerName}`, margin, margin + additionalSpace + 25);
    doc.text(`Address: ${customerAddress}`, margin, margin + additionalSpace + 30);
    doc.text(`Mobile: ${customerPhone}`, margin, margin + additionalSpace + 35);

    // Separator
    drawSeparator(doc, margin + additionalSpace + 40);

    // Order Details
    doc.setFontSize(10);
    doc.text(`Order ID: ${orderId}`, margin, margin + additionalSpace + 50);
    doc.text(`Payment Type: ${paymentType}`, margin, margin + additionalSpace + 55);

    // Total Amount
    doc.setFontSize(10);
    doc.setFont('Courier', 'bold');
    doc.text(`Total: ${totalAmount}`, margin, margin + additionalSpace + 65);

    // Separator
    drawSeparator(doc, margin + additionalSpace + 70);

    // Thank You Message
    doc.setFontSize(8);
    doc.setFont('Courier', 'italic');
    doc.text(thankYouMessage, 80 / 2, margin + additionalSpace + 80, { align: 'center' });

    // Footer Message
    doc.setFontSize(8);
    doc.setFont('Courier', 'normal');
    doc.text(footerMessage, 80 / 2, margin + additionalSpace + 90, { align: 'center' });
    doc.text(storeNumber, 80 / 2, margin + additionalSpace + 95, { align: 'center' });

    // QR Code
    const qrCodeImage = await loadImage(qrCodeUrl);
    const qrSize = 40; // Size of QR code
    const qrX = (80 - qrSize) / 2; // Center align the QR code horizontally
    doc.addImage(qrCodeImage, 'PNG', qrX, margin + additionalSpace + 100, qrSize, qrSize); // Adjust size and position

    // Open the PDF in a new window and trigger print
    const pdfData = doc.output('bloburl');
    const printWindow = window.open(pdfData);
    printWindow.addEventListener('load', () => {
      printWindow.print();
    });
  };

  const drawSeparator = (doc, y) => {
    const lineWidth = 80 - 2 * 5; // Full width minus margins
    const dashLength = 2; // Length of each dash
    const spaceLength = 1; // Space between dashes

    let x = 5; // Starting x position

    doc.setFontSize(8);
    doc.setFont('Courier', 'normal');

    while (x < lineWidth) {
      doc.text('-', x, y);
      x += dashLength + spaceLength;
    }
  };

  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  };

  return (
    <div>
      <button className="add-customer-button" onClick={handlePrint}>Print Receipt</button>
    </div>
  );
};

export default PosReceipt;
