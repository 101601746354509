import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { gsap } from 'gsap';
import Navbar from '../Navbar/Navbar';
import '../../styles/UserPage.css';

const UserPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const access_token = localStorage.getItem('token');
    if (!access_token) {
      navigate('/login');
    } else {
      try {
        jwtDecode(access_token);
      } catch (error) {
        console.error('Invalid token:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        navigate('/login');
      }
    }
  }, [navigate]);

  useEffect(() => {
    gsap.fromTo('.card4', 
      { opacity: 0, y: 20 }, 
      { opacity: 1, y: 0, duration: 1.7, stagger: 0.1, ease: 'power2.out' }
    );

    gsap.fromTo('.card4 h3', 
      { scale: 0.8, opacity: 0 }, 
      { scale: 1, opacity: 1, duration: 0.3, stagger: 0.1, ease: 'back.out(1.7)' }
    );
  }, []);

  const cards = [
    { title: 'Order', path: '/orders' },
    { title: 'Customer', path: '/customers' },
  ];

  return (
    <div>
      <Navbar />
      <div className="userpage4">
        <div className="card-container4">
          {cards.map((card, index) => (
            <Link key={index} to={card.path} className="card-link4">
              <div className="card4">
                <h3>{card.title}</h3>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserPage;
