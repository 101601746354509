class ApiEndpoints {
    // static BASE_URL = 'https://vendors-api.zeliver.in';
    static BASE_URL = 'https://vendors-api-test.zeliver.in';

    static LOGIN_URL = '/api/v1/auth/login';
    static REFRESH_URL = '/api/v1/auth/refresh';
    static LOGOUT_URL = '/api/v1/auth/logout';

    static PRODUCTLIST_URL = '/api/v1/product';
    static PRODUCTID_URL = '/api/v1/product/';
    static CREATE_PRODUCT_URL = '/api/v1/product';
    static DELETE_IMAGE_URL = '/files/delete';
    static ACCESSORIESLIST_URL = '/api/v1/accessories';
    static SERVICEREQUEST_URL = '/api/v1/service-request';
    static USER_TYPE = '/api/v1/users/';

    static USER_TYPE_USER_SEARCH = '/api/v1/users/search';

    static ADDRESS_URL ='/api/v1/customer-addresses'


    static BASEURL_IMAGE = 'https://assets.admiro.buzz/';
    static PRODUCT_THUMBNAIL_UPLOAD_URL = '/files/upload/product';
    static PROFILE_URL = '/api/v1/users/profile';
    static TECHNICIAN_INVENTORY = '/api/v1/technician-inventory';
    static POS_URL = '/api/v1/user-items';

    static ORDER_URL ='/api/v1/orders';
    static ADD_ADDRESS='/api/v1/customer-addresses'

    static ORDER_VENDOR = '/api/v1/orders/vendor';
    static VENDOR_CUSTOMER_LIST = '/api/v1/users/vendor/customer/list';
    static VENDOR_CUSTOMER_SEARCH = '/api/v1/users/search-customers/vendors';
    static VENDOR_ADDRESS = '/api/v1/vendor/customer-addresses'
  }

  export default ApiEndpoints;
  