import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './components/LoginPage/LoginPage';
import AdminPage from './pages/AdminPage/AdminPage';
import Profile from './pages/Profile/Profile';
import Vendor from './pages/Vendor/Vendor';
import Customer from './pages/Customer/Customer';
import Order from './pages/Order/Order';
import Customers from './pages/Customers/Customers';
import Orders from './pages/Orders/Orders';
import './styles/App.css';
import 'react-toastify/dist/ReactToastify.css';
import UserPage from './pages/CustomerPage/CustomerPage';

const App = () => {
  return (
    <div className="App">
      <main>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/homeA" element={<AdminPage />} />
          <Route path="/homeC" element={<UserPage />} />
          <Route path="/vendor" element={<Vendor />} />
          <Route path="/order" element={<Order />} />
          <Route path="/user" element={<Customer />} />


          <Route path="/orders" element={<Orders />} />
          <Route path="/customers" element={<Customers />} />

          <Route path="/profile" element={<Profile />} />

        </Routes>
      </main>
    </div>
  );
}

export default App;
