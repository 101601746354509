import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';
import { Autocomplete, TextField, CircularProgress, FormControl, FormHelperText } from '@mui/material';
import '../../styles/UserSearchDropdown.css';

const UserSearchDropdown = ({ onUserSelect, userName }) => {
  const [query, setQuery] = useState(userName || '');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setQuery(userName || '');
  }, [userName]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (query.length >= 2) {
        setLoading(true);
        try {
          const access_token = localStorage.getItem('token');
          if (!access_token) {
            throw new Error('No token found');
          }
          const response = await axios.get(`${ApiEndpoints.BASE_URL}${ApiEndpoints.VENDOR_CUSTOMER_SEARCH}`, {
            params: {
              name: query
            },
            headers: {
              Authorization: `Bearer ${access_token}`,
              'Content-Type': 'application/json'
            }
          });
          setUsers(response.data);
          setError('');
        } catch (error) {
          console.error('Error fetching users:', error);
          setError('Failed to load users');
        } finally {
          setLoading(false);
        }
      } else {
        setUsers([]);
      }
    };

    fetchUsers();
  }, [query]);

  return (
    <FormControl fullWidth error={Boolean(error)}>
      <Autocomplete
        freeSolo
        options={users}
        getOptionLabel={(option) => option.name || ''}
        value={users.find((user) => user.name === query) || null}
        onInputChange={(event, newInputValue) => {
          if (newInputValue === '') {
            // Clear selection when "X" is clicked
            setQuery('');
            onUserSelect(null); // Reset the selected user
          } else {
            setQuery(newInputValue);
          }
        }}
        onChange={(event, newValue) => {
          if (newValue) {
            onUserSelect(newValue);
            setQuery(newValue.name);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search Customers"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default UserSearchDropdown;
