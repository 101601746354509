// src/routeConfig.js

const routeConfig = {
    '/homeA': '/homeA',
    '/homeA/vendor': '/homeA',
    '/homeA/order': '/homeA',
    '/homeA/user': '/homeA',

    '/homeC': '/homeC',
    '/homeC/orders': '/homeC',
    '/homeC/customers': '/homeC',
  };
  
  export default routeConfig;
  