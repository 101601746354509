import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';
import '../../styles/Orders.css'; // Adjust path to CSS file as needed
import UserSearchDropdown from '../UserSearchDropdown/UserSearchDropdown';
import UserSearchDropdown1 from '../UserSearchDropDown1/UserSearchDropDown1';
import Navbar from '../Navbar/Navbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import PosReceipt from '../../POSprint/POSprint';
import GoogleMapComponent from '../../components/GoogleMap/GoogleMap';
import { FaFilter } from 'react-icons/fa'; // import font-awesome filter icon
import { refreshToken as authServiceRefreshToken } from '../../services/authService';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const OrderList = () => {
  const [orders, setorders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  // const [searchTerm, setSearchTerm] = useState('');
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [manualMobileNumber, setManualMobileNumber] = useState('');
  // const [showAddAddressPopup, setShowAddAddressPopup] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [errors, setErrors] = useState({});
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [filterType, setFilterType] = useState('all');
  const [selectedUser, setSelectedUser] = useState(null);
  const [singleDate, setSingleDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [userName, setUserName] = useState('');
  const { logout } = useAuth();

  const [newOrderData, setnewOrderData] = useState({
    vendorId: localStorage.getItem('userid'),
    name: '',
    customerAddress: {
      address: '',
      userId: '',
      latitude: 0,
      longitude: 0,
      formattedAddress: '',
      placeId: '',
    },
    mobileNumber: '',
    orderAmount: '',
    paymentType: 'PREPAID',
    deliverySlotType: 'INSTANT'
  });


  // const [newCustomer, setNewCustomer] = useState({
  //   name: '',
  //   customerAddress: {
  //     address: '',
  //     userId: ''
  //   },
  //   mobileNumber: '',
  //   orderAmount: '',
  //   paymentType: ''
  // });

  const [addressFormData, setAddressFormData] = useState({
    address: '',
    userId: '',
    latitude: 0,
    longitude: 0,
    formattedAddress: '',
    placeId: '',
  });

  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    page: '1',
    size: '10',
    totalElements: '',
    totalPages: '',
  });


  const constructUrl = () => {
    const userId = localStorage.getItem('userid');
    const baseUrl = `${ApiEndpoints.BASE_URL}${ApiEndpoints.ORDER_VENDOR}`;
    const pageParams = `?page=${pagination.page}&size=${pagination.size}`;
    let url;

    if (showSearchBar && selectedUser) {
      // API URL when a user is selected
      url = `${baseUrl}/customer/${selectedUser.id}/list${pageParams}&filterMode=${filterType}`;
      if (filterType === 'single') {
        url += `&date=${singleDate}`;
      } else if (filterType === 'range') {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }
    } else {
      // API URL without user selection
      url = `${baseUrl}/${userId}/list${pageParams}&filterMode=${filterType}`;
      if (filterType === 'single') {
        url += `&date=${singleDate}`;
      } else if (filterType === 'range') {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }
    }
    return url;
  };


  const fetchorders = async () => {
    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }

      const url = constructUrl();

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          'Content-Type': 'application/json',
        },
      });

      if (Array.isArray(response.data.content)) {
        setorders(response.data.content);
        setPagination({
          hasNextPage: response.data.hasNextPage,
          hasPreviousPage: response.data.hasPreviousPage,
          page: response.data.page,
          size: response.data.size,
          totalElements: response.data.totalElements,
          totalPages: response.data.totalPages,
        });

      } else {
        console.error('Unexpected data format:', response.data);
        setorders([]);
        toast.error('Failed to load orders. Data format error.'); // Error toast notification
      }
      setLoading(false);
    } catch (err) {
      const status = err.response?.status;

      if (status === 401) {
        console.log('Access token expired. Redirecting to login...');
        toast.error('Access token expired. Redirecting to login...');
        logout();
      } else if (status === 403) {
        console.log('Forbidden: Attempting token refresh...');
        toast.info('Token expired. Attempting to refresh...');
        try {
          const refreshResponse = await authServiceRefreshToken(localStorage.getItem('refreshToken'));

          if (refreshResponse.access_token && refreshResponse.refresh_token) {
            console.log('Token refreshed successfully. Saving tokens and retrying order fetch...');
            localStorage.setItem('token', refreshResponse.access_token);
            localStorage.setItem('refreshToken', refreshResponse.refresh_token);

            fetchorders();
          } else {
            console.error('Invalid token response during refresh:', refreshResponse);
            toast.error('Token refresh failed. Logging out...');
            logout();
          }
        } catch (refreshError) {
          console.error('Refresh token failed. Logging out...', refreshError);
          toast.error('Refresh token failed. Logging out...');
          logout();
        }
      } else {
        console.error('An error occurred while fetching orders:', err);
        setError('Failed to load orders. Please try again later.');
        toast.error('Failed to load orders. Please try again later.'); // General error toast
      }
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchorders();
  }, [selectedUser, filterType, singleDate, startDate, endDate, pagination.size, pagination.page]);


  const handlePageChange = (event, value) => {
    setPagination((prev) => ({
      ...prev,
      page: value,
    }));
  };




  const handleManualInputChange = (value) => {
    const mobileNumberPattern = /^\d{10}$/; // Regular expression for a 10-digit number

    if (value.trim() === '' || !mobileNumberPattern.test(value)) {
      // Clear the input and set the error if the number is invalid
      setManualMobileNumber('');
      setErrors(prevErrors => ({
        ...prevErrors,
        mobileNumber: 'Mobile number must be exactly 10 digits',
      }));
      setnewOrderData(prevData => ({
        ...prevData,
        mobileNumber: '',
        name: '',
        customerAddress: {
          address: '',
          latitude: 0,
          longitude: 0,
          formattedAddress: '',
          placeId: '',
        }
      }));
      setSelectedLocation({
        address: '',
        latitude: 11.1380481,
        longitude: 77.3249964,
        formattedAddress: '',
        placeId: '',
      });
    } else {
      // If the input is valid, update the mobile number
      setManualMobileNumber(value);
      setErrors(prevErrors => ({
        ...prevErrors,
        mobileNumber: '', // Clear the error when input is valid
      }));
      setnewOrderData(prevData => ({
        ...prevData,
        mobileNumber: value,  // Update mobileNumber with valid input
      }));
    }
  };







  const handleCustomerSelection = (user) => {
    if (user) {
      // Update selected customer
      setSelectedCustomer(user);

      // Update new order data with customer details
      setnewOrderData(prevData => ({
        ...prevData,
        mobileNumber: user.mobileNumber || manualMobileNumber,
        name: user?.name ?? prevData.name,
        customerAddress: user?.customerAddress
          ? {
            ...prevData.customerAddress, // Preserve existing address details
            address: user.customerAddress.address,
            latitude: user.customerAddress.latitude ?? 0,
            longitude: user.customerAddress.longitude ?? 0,
            formattedAddress: user.customerAddress.address, // Ensure formattedAddress is updated
            placeId: user.customerAddress.placeId || '', // Ensure placeId is updated
          }
          : {
            ...prevData.customerAddress, // Preserve existing address details
            address: '', // Clear address if no customer address is provided
            latitude: 0,
            longitude: 0,
            formattedAddress: '', // Ensure formattedAddress is cleared
            placeId: '', // Ensure placeId is cleared
          },
      }));

      // Update map location if necessary
      const updatedAddress = user?.customerAddress
        ? {
          address: user.customerAddress.address,
          latitude: user.customerAddress.latitude ?? 0,
          longitude: user.customerAddress.longitude ?? 0,
          formattedAddress: user.customerAddress.address,
          placeId: user.customerAddress.placeId || '',
        }
        : {
          address: '',
          latitude: 0,
          longitude: 0,
          formattedAddress: '',
          placeId: '',
        };

      setSelectedLocation(updatedAddress);

      // Debugging: log the selected customer ID
      console.log('Selected Customer ID:', user.id);
    } else {
      // If no customer is selected, clear all related fields
      setSelectedCustomer(null);
      setnewOrderData(prevData => ({
        ...prevData,
        mobileNumber: '',
        name: '',
        customerAddress: {
          address: '',
          latitude: 0,
          longitude: 0,
          formattedAddress: '',
          placeId: '',
        }
      }));
      setSelectedLocation({
        address: '',
        latitude: 0,
        longitude: 0,
        formattedAddress: '',
        placeId: '',
      });
    }
  };



  const handleAddressSelection = (event) => {
    const selectedAddressId = event.target.value;

    if (selectedAddressId) {
      // Update the new order data state with the selected customer address ID
      setnewOrderData(prevData => ({
        ...prevData,
        customerAddressId: selectedAddressId
      }));
    }
  };


  // const handleLocationSelect = (location) => {
  //   setFormData(prevData => ({
  //     ...prevData,
  //     latitude: location.lat,
  //     longitude: location.lng,
  //     formattedAddress: location.formattedAddress,
  //     placeId: location.placeId
  //   }));
  // };

  const handleFilterButtonClick = () => {
    setFilterVisible((prevState) => !prevState); // Toggle the state
  };



  useEffect(() => {
    fetchorders();
  }, [singleDate]);

  useEffect(() => {
    fetchorders();
  }, [endDate]);

  useEffect(() => {
    fetchorders();
  }, [filterType]);

  const handleAddOrder = async (event) => {
    event.preventDefault();

    // Clear previous errors
    setErrors({});
    setLoading(true);

    // Validation checks
    const newErrors = {};

    if (!newOrderData.name) {
      newErrors.name = 'Customer name is required';
    }

    if (!newOrderData.customerAddress?.address) {
      newErrors.deliveryLocation = 'Delivery location is required';
    }

    if (!newOrderData.paymentType) {
      newErrors.paymentType = 'Payment type is required';
    }

    if (newOrderData.paymentType === 'POD' && !newOrderData.orderAmount) {
      newErrors.orderAmount = 'Order amount is required for POD payments';
    }

    if (!selectedCustomer && !manualMobileNumber) {
      newErrors.mobileNumber = 'Mobile number is required and must be valid';
    }

    if (Object.keys(newErrors).length > 0) {
      for (let field in newErrors) {
        toast.error(newErrors[field]);
      }
      setErrors(newErrors);
      setLoading(false);
      return;
    }

    try {
      const access_token = localStorage.getItem('token');
      if (!access_token) {
        throw new Error('No token found');
      }

      const response = await axios.post(
        `${ApiEndpoints.BASE_URL}${ApiEndpoints.ORDER_URL}`,
        newOrderData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setSelectedCustomer(null);
        setnewOrderData({
          vendorId: localStorage.getItem('userid'),
          name: '',
          customerAddress: {
            address: '',
            userId: '',
          },
          mobileNumber: '',
          orderAmount: '',
          paymentType: 'PREPAID',
          deliverySlotType: 'INSTANT',
        });
        setManualMobileNumber('');
        setShowAddPopup(false);

        toast.success('Order added successfully!');
        fetchorders();
      } else {
        console.error('Failed to add order:', response);
        setError('Failed to add order. Please try again later.');
        toast.error('Failed to add order. Please try again later.');
      }
    } catch (error) {
      console.error('Error adding order:', error);
      setError('Error adding order. Please try again later.');
      toast.error('Error adding order. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // const handleNewCustomerAddress = async (event) => {
  //   event.preventDefault();
  //   try {
  //     const access_token = localStorage.getItem('token');
  //     if (!access_token) {
  //       throw new Error('No token found');
  //     }

  //     const response = await axios.post(
  //       `${ApiEndpoints.BASE_URL}${ApiEndpoints.VENDOR_ADDRESS}`,
  //       newCustomer,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${access_token}`,
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       setShowAddPopup(false);
  //       toast.success('Order added successfully!');
  //       fetchorders();
  //     } else {
  //       console.error('Failed to add order:', response);
  //     }
  //   } catch (error) {
  //     console.error('Error adding order:', error);
  //     setError('Error adding order. Please try again later.');
  //   }
  // };

  // const handleSearchChange = (event) => {
  //   setSearchTerm(event.target.value);
  // };

  // const filteredorders = orders.filter(
  //   (order) =>
  //     order && order.customer && order.customer.name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  const handleAddCustomerClick = () => {
    setShowAddPopup(true);
  };

  const handleClosePopup = () => {
    setSelectedCustomer(null);
    // Reset the states to their initial values
    setnewOrderData({
      vendorId: localStorage.getItem('userid'),
      name: '',
      customerAddress: {
        address: '',
        userId: '',
        latitude: 0,
        longitude: 0,
        formattedAddress: '',
        placeId: '',
      },
      mobileNumber: '',
      orderAmount: '',
      paymentType: 'PREPAID',
      deliverySlotType: 'INSTANT',
    });
    setSelectedLocation(null);
    setManualMobileNumber('');

    // Close the popup
    setShowAddPopup(false);
  };


  // const handlecustomerselection = (user) => {
  //   setselectedOrder(user);
  // };



  function formatDate(dateString) {
    const date = new Date(dateString);

    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);

    const day = date.getDate().toString().padStart(2, '0');
    const month = date.toLocaleString('en-US', { month: 'short' }).toUpperCase();
    const year = date.getFullYear().toString().slice(-2);
    const time = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

    return `${day} ${month} ${year} | ${time}`;
  }

  // const handleCustomerNameChange = (e) => {
  //   setNewCustomer(e.target.value);
  // };

  const handleLocationSelect = (location) => {
    setAddressFormData(prevData => ({
      ...prevData,
      latitude: location.latitude,
      longitude: location.longitude,
      formattedAddress: location.formattedAddress,
      placeId: location.placeId,
      address: location.formattedAddress
    }));
    setnewOrderData(prevData => ({
      ...prevData,
      customerAddress: {
        ...prevData.customerAddress,
        address: location.formattedAddress,
        latitude: location.latitude,
        longitude: location.longitude,
        formattedAddress: location.formattedAddress,
        placeId: location.placeId,
      }
    }));


    console.log(addressFormData);
  };

  // const handleAddAddress = (e) => {
  //   e.preventDefault();
  //   console.log('Address Data:', addressFormData);
  //   setShowAddAddressPopup(false);
  // };

  // const handleAddAdressInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setAddressFormData(prevData => ({
  //     ...prevData,
  //     [name]: value
  //   }));
  // };

  // const handleMapClick = (locationData) => {
  //   const { lat, lng, formattedAddress, placeId } = locationData;

  //   setAddressFormData(prevData => ({
  //     ...prevData,
  //     latitude: lat,
  //     longitude: lng,
  //     formattedAddress,
  //     placeId
  //   }));
  // };


  useEffect(() => {
    const today = new Date();
    const currentDate = today.toISOString().split("T")[0]; // Current date: YYYY-MM-DD

    // Explicitly create the first day of the current month in UTC
    const startOfMonth = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 1))
      .toISOString()
      .split("T")[0];

    if (filterType === "single") {
      setSingleDate(currentDate);
    } else if (filterType === "range") {
      setStartDate(startOfMonth); // First day of the current month (UTC)
      setEndDate(currentDate); // Today
    }
  }, [filterType]);



  const handleRadioChange = (e) => {
    setShowSearchBar(e.target.value === "yes");
  };

  const handleFilterTypeChange = (e) => {
    setFilterType(e.target.value);
  };

  const handleSingleDateChange = (e) => {
    setSingleDate(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const isDateSelected = () => {
    if (filterType === "single") {
      return singleDate !== "";
    }
    if (filterType === "range") {
      return startDate !== "" && endDate !== "";
    }
    return true;
  };

  useEffect(() => {
    // Reset states when toggling showSearchBar
    setFilterType('all');
    setSelectedUser(null);
    setSingleDate('');
    setStartDate('');
    setEndDate('');
    setUserName('');
  }, [showSearchBar]);


  const handleUserSelection = (user) => {
  if (user) {
    // When a user is selected
    setSelectedUser(user);
    setUserName(user.name); // Assuming `user` has a `name` field
  } else {
    // When clearing the search bar
    setSelectedUser(null);
    setUserName(''); // Clear the displayed name
  }
};


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <ToastContainer />
      <Navbar />

      <div className="customer-list-container">
        <div className="customer-list-actions">
          {/* <input
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input06"
          /> */}

          <div className="filter-container1">
            {/* First Line: Filter by Customer and Filter Type */}
            <div className="filter-line1">
              <span>Filter by Customer:</span>
              <label>
                <input
                  type="radio"
                  value="yes"
                  checked={showSearchBar === true}
                  onChange={handleRadioChange}
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  value="no"
                  checked={showSearchBar === false}
                  onChange={handleRadioChange}
                />
                No
              </label>

              <label>
                <input
                  type="radio"
                  value="all"
                  checked={filterType === 'all'}
                  onChange={handleFilterTypeChange}
                />
                All
              </label>
              <label>
                <input
                  type="radio"
                  value="single"
                  checked={filterType === 'single'}
                  onChange={handleFilterTypeChange}
                />
                Single Date
              </label>
              <label>
                <input
                  type="radio"
                  value="range"
                  checked={filterType === 'range'}
                  onChange={handleFilterTypeChange}
                />
                Date Range
              </label>
            </div>
            {/* in this after clicking  clear the name in the serach bar ,it go to initial api call , dont show the serached name */}

            {/* Second Line: Conditional Search Bar and Calendar */}
            <div className="dynamic-content-wrapper">
              {showSearchBar && (
                <div className="search-bar-wrapper1">
                  <UserSearchDropdown1
                    onUserSelect={handleUserSelection}
                    userName={userName}
                  />
                </div>
              )}
              {(filterType === 'single' || filterType === 'range') && (
                <div className="calendar-section1">
                  {filterType === 'single' && (
                    <label>
                      Select Date:
                      <input
                        type="date"
                        value={singleDate}
                        onChange={handleSingleDateChange}
                      />
                    </label>
                  )}
                  {filterType === 'range' && (
                    <div>
                      <label>
                        Start Date:
                        <input
                          type="date"
                          value={startDate}
                          onChange={handleStartDateChange}
                        />
                      </label>
                      <label>
                        End Date:
                        <input
                          type="date"
                          value={endDate}
                          onChange={handleEndDateChange}
                        />
                      </label>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <h1>Order List</h1>
          <button className="add-customer-button" onClick={handleAddCustomerClick}>Create Order</button>
        </div>
        <div className="table-responsive">
          <table className="customer-list-table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Order ID</th>
                <th>Customer Name</th>
                <th>Mobile Number</th>
                <th>Amount</th>
                <th>Order Date</th>
                <th>PaymentType</th>
                <th>Order Status</th>
                <th>Print Receipt</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <tr key={order.id}>
                  <td>{(pagination.page - 1) * pagination.size + index + 1}</td>
                  <td>{order.id}</td>
                  <td>{order.customer.name}</td>
                  <td>{order.customer.mobileNumber}</td>
                  {/* <td>{order.customerAddress.contactNumber}</td> */}
                  <td>{order.orderAmount}</td>
                  <td>{formatDate(order.createdAt)}</td>
                  <td>{order.paymentType}</td>
                  <td>{order.status}</td>
                  <td>
                    <PosReceipt
                      customerAddress={order.customerAddress?.address || ''}
                      customerPhone={order.customer?.mobileNumber || ''}
                      orderId={order.id}
                      customerName={order.customer?.name || ''}
                      paymentType={order.paymentType || ''}
                      totalAmount={order.orderAmount ? `${order.orderAmount.toFixed(2)}` : '₹0.00'}
                      thankYouMessage="Thank You For Your Purchase!"
                      footerMessage="Feedback Appreciated"
                      storeNumber="8110995777"
                      upiId={localStorage.getItem('upi')}
                    />
                  </td>
                </tr>
              ))}
            </tbody>

          </table>
        </div>

        {showAddPopup && (
          <div className="customer-popup">
            <div className="customer-popup-content">
              <h2>Create Order</h2>
              <form onSubmit={handleAddOrder}>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label style={{ marginRight: '13px' }}> Mobile Number :</label>
                  <div style={{ flex: 1 }}>
                    <UserSearchDropdown
                      onUserSelect={handleCustomerSelection}
                      userName={selectedCustomer?.mobileNumber || manualMobileNumber}
                      onManualInputChange={handleManualInputChange}
                      required
                      pattern="\d{10}"
                      maxLength="10"
                    />
                  </div>
                </div>

                <div className="input-group1">
                  <label>Customer Name:</label>
                  <input
                    type="text"
                    className="line-input1"
                    value={newOrderData.name}
                    onChange={(e) => setnewOrderData({ ...newOrderData, name: e.target.value })}
                    required
                  />
                </div>

                <div className="form-container" style={{ marginTop: '-5px' }}>
                  <label>Delivery Location:</label>
                  <div className="google-map-container">
                    <GoogleMapComponent
                      onSelectLocation={handleLocationSelect}
                      selectedLocation={selectedLocation}
                      required
                    />
                  </div>
                </div>

                <div className="input-group1 order">
                  <label> Mode Of Payment:</label>
                  <div className="order-input-group">
                    <label>
                      <input
                        type="radio"
                        name="paymentType"
                        value="PREPAID"
                        checked={newOrderData.paymentType === 'PREPAID'}
                        onChange={(e) => setnewOrderData({ ...newOrderData, paymentType: e.target.value })}
                        className="order-input"
                        required
                      />
                      Prepaid
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="paymentType"
                        value="POD"
                        checked={newOrderData.paymentType === 'POD'}
                        onChange={(e) => setnewOrderData({ ...newOrderData, paymentType: e.target.value })}
                        className="order-input"
                        required
                      />
                      POD
                    </label>
                  </div>
                </div>

                {newOrderData.paymentType === 'POD' && (
                  <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '4px', marginTop: '-30px' }}>
                    <label style={{ marginRight: '8px', whiteSpace: 'nowrap' }}>Order Amount:</label>
                    <input
                      type="number"
                      value={newOrderData.orderAmount}
                      onChange={(e) => setnewOrderData({ ...newOrderData, orderAmount: e.target.value })}
                      required
                      style={{ marginLeft: '13px', border: 'none', borderBottom: '1px solid #000', outline: 'none', width: '250px' }}
                    />
                  </div>
                )}

                {/* Buttons */}
                <div className="button-container32">
                  <button type="button" className="close-button32" onClick={handleClosePopup}>Close</button>
                  <button type="button" className="cancel-button32" onClick={handleClosePopup}>Cancel</button>
                  <button type="submit" className="save-button32">Create Order</button>
                </div>
              </form>
            </div>
          </div>
        )}


        <div className="pagination1">
          <Stack spacing={2}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.page}
              onChange={handlePageChange}
              hidePrevButton={false}
              hideNextButton={false}
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  className={item.type === 'page' ? 'MuiPaginationItem-page' : item.type === 'previous' || item.type === 'next' ? `MuiPaginationItem-${item.type}` : ''}
                  selected={item.page === pagination.page}
                  slots={{
                    previous: () => <span>Previous</span>,
                    next: () => <span>Next</span>,
                  }}
                />
              )}
            />
          </Stack>
        </div>

      </div>
    </div>
  );
};

export default OrderList;
