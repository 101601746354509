import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { login as authServiceLogin, refreshToken as authServiceRefreshToken, logout as authServiceLogout } from '../services/authService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [tokenExpiredMessage, setTokenExpiredMessage] = useState(null);
  const navigate = useNavigate();

  const logout = useCallback(async () => {
    console.log('Logging out...');
    setUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    await authServiceLogout();
    navigate('/login');
  }, [navigate]);

  const refreshToken = useCallback(async (refresh_token) => {
    try {
      console.log('Refreshing token...');
      const data = await authServiceRefreshToken(refresh_token);
      if (data && data.access_token) {
        const newDecodedUser = jwtDecode(data.access_token);
        console.log('New token decoded:', newDecodedUser);
        setUser(newDecodedUser);
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('refreshToken', data.refresh_token);
      } else {
        console.log('No new token received, logging out...');
        setTokenExpiredMessage('Your session has expired. Please log in again.');
        logout();
      }
    } catch (error) {
      console.error('Refresh token error:', error);
      setTokenExpiredMessage('Your session has expired. Please log in again.');
      logout();
    }
  }, [logout]);

  useEffect(() => {
    console.log('Checking authentication status...');
    const token = localStorage.getItem('token');
    console.log('Stored token:', token);
    if (token) {
      try {
        const decodedUser = jwtDecode(token);
        console.log('Token decoded:', decodedUser);
        setUser(decodedUser);
      } catch (error) {
        console.error('Invalid token:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        logout();
      }
    } else {
      console.log('No token found, user is not authenticated.');
      setUser(null);
    }
  }, [logout]);

  const login = async (email, password) => {
    try {
      console.log('Attempting login...');
      const data = await authServiceLogin(email, password);
      if (data.access_token) {
        const decodedUser = jwtDecode(data.access_token);
        console.log('Login successful, user:', decodedUser);
        setUser(decodedUser);
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('refreshToken', data.refresh_token);
      }
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
    return null;
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, tokenExpiredMessage, refreshToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
