import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ApiEndpoints from '../../ApiEndpoints/ApiEndpoints';
import { Autocomplete, TextField, CircularProgress, FormControl, FormHelperText } from '@mui/material';

const UserSearchDropdown = ({ onUserSelect, userName, onManualInputChange }) => {
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState(userName || '');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      if (search.length >= 3) {  // Trigger API call if search length is at least 3
        setLoading(true);
        setError('');
        try {
          const accessToken = localStorage.getItem('token');
          if (!accessToken) {
            throw new Error('No token found');
          }

          const response = await axios.get(
            `${ApiEndpoints.BASE_URL}${ApiEndpoints.VENDOR_CUSTOMER_SEARCH}`,
            {
              params: {
                name: search
              },
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          setOptions(response.data);
        } catch (error) {
          console.error('Error fetching users:', error);
          setError('Failed to load users');
        } finally {
          setLoading(false);
        }
      } else {
        setOptions([]);
      }
    };

    fetchUsers();
  }, [search]);

  const handleManualInputChange = (newInputValue) => {
    // Allow only numeric input and restrict length to 10 digits
    if (/^\d{0,10}$/.test(newInputValue)) {
      setSearch(newInputValue);
      onManualInputChange(newInputValue);
    }
  };

  const handleSubmit = () => {
    if (search.length !== 10) {
      setError('Mobile number must be exactly 10 digits');
      return;
    }
    setError('');
    // Proceed with form submission or other logic
  };



  return (
    <FormControl fullWidth error={Boolean(error)}>
      <Autocomplete
        freeSolo
        options={options}
        getOptionLabel={(option) => option.mobileNumber || ''}
        inputValue={search} 
        onInputChange={(event, newInputValue) => {
          handleManualInputChange(newInputValue); 
        }}
        onChange={(event, newValue) => {
          if (newValue) {
            setSearch(newValue.mobileNumber);
            onUserSelect(newValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Search Numbers'
            variant="outlined"
            
            sx={{
              '& .MuiOutlinedInput-root': {
                border: 'none',
                borderBottom: '1px solid #000', 
                borderRadius: 0, 
                padding: 0,
                margin: 0,
                width:250
              },
              '& .MuiInputBase-input': {
                padding: '10px 0',
                margin: 0,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default UserSearchDropdown;
